import React from "react";
import { TypeAnimation } from "react-type-animation";

type Sequence = Array<SequenceElement>;
type AnimatedElement = string | number | boolean;
type SequenceElement = string | number | ((element: HTMLElement | null) => void | Promise<void>);
type Wrapper = 'p' | 'div' | 'span' | 'strong' | 'a' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'b';

export type AnimatedTextProps = {
  wrapper?: Wrapper;
  text: AnimatedElement[];
}


export const AnimatedText = ({text, wrapper}:AnimatedTextProps) => {
  const CURSOR_CLASS_NAME = 'animated-cursor';
  const ref = React.createRef<HTMLSpanElement>();

  const showCursorAnimation = (el: HTMLSpanElement|null, show: boolean) => {
    if (show) {
      el?.classList.add(CURSOR_CLASS_NAME);
    } else {
      el?.classList.remove(CURSOR_CLASS_NAME);
    }
  };

  const sequence = text.map(s => typeof(s) == "boolean" ? () => showCursorAnimation(ref.current, s as boolean) : s as SequenceElement) as Sequence;// useState<Sequence>([]);

  return (
    <TypeAnimation
      ref={ref}
      sequence={sequence}
      wrapper={wrapper ?? 'span'}
      cursor={false}
      className={CURSOR_CLASS_NAME} 
      style={{  display: 'inline-block', color:  'white' }}
    />
  );
}