import React from 'react';
import './App.css';
import { AnimatedText } from './components/AnimatedText';

function App() {
  return (
    <div className="container">
      <img src={'logo1.png'} className="logo" alt="logo" />
      <div className='text'>
        <AnimatedText text={[true, 1000, "Unghost.me", false]} wrapper='h1'/>
        <AnimatedText text={[false, 1500, true, 2000, "No swiping, just a genuine connection.", false]} wrapper='span'/>
        <AnimatedText text={[false, 6000, true, 2000, "Chat instantly and unghost the person's profile picture.", true, 100000, false]} wrapper='span'/>
      </div>
      <div className='stores'>
        <img src={'playstore.png'} alt='playstore' />
        <img src={'appstore.png'} alt='appstore' />
      </div>  
      <div className='footer'>
        Copyright © {new Date().getFullYear()} <a href='mailto:info@unghost.me'>Unghost.me</a>. All rights reserved.
      </div>    
    </div>
  );
}

export default App;
